<template>
    <user-dashboard-layout>
        <v-container fluid grid-list-md class="ma-0">
            <v-form>
            <v-card>
                <v-card-text>
                    ILLINOIS FAIR PLAN ASSOCIATION
                    COMMERCIAL INSPECTION REPORT

                </v-card-text>
            </v-card>
            <v-col>


                    <v-text-field
                        label="Name of Applicant"
                        v-model="form.applicant_name"
                    >

                    </v-text-field>

                <v-text-field
                    label="Property Address"
                    v-model="form.property_address"
                >

                </v-text-field>

                <v-text-field
                    label="Fire Divison Address (if Different)"
                    v-model="form.fire_divison_address"
                >

                </v-text-field>

                <v-text-field
                    label="City"
                    v-model="form.city"
                >

                </v-text-field>

                <v-radio-group
                    v-model="form.within_city_limits"
                    row
                    label="Within city limits"
                >
                    <v-radio
                        label="Yes"
                        value="Yes"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value=No
                    ></v-radio>
                </v-radio-group>



                <v-text-field
                    label="date"

                >

                </v-text-field>

                <v-text-field
                    label="Name of Person Contacted"
                    v-model="form.person_contacted_name"
                ></v-text-field>
                    <v-radio-group
                    v-model="form.interior_inspection"
                    row
                    label="Was an interior inspection made?"
                >
                    <v-radio
                        label="Yes"
                        value="Yes"
                    >

                    </v-radio>
                    <v-radio
                        label="No"
                        value="No"
                    ></v-radio>
                </v-radio-group>




                <v-text-field
                    label="Insp Co"
                    v-model="form.insp_co"
                >

                </v-text-field>
                <v-text-field
                    label="Inspector"
                    v-model="form.inspector"
                >

                </v-text-field>
                <v-text-field
                    label="File"
                    v-model="form.file"
                >

                </v-text-field>
                <v-text-field
                    label="Inspection"
                    v-model="form.inspection"
                >

                </v-text-field>


            </v-col>
        <v-col>
            <v-card-title>
                A. THE PROPERTY
            </v-card-title>

            <v-radio-group
                v-model="form.property_vacant"
                row
                label="Is property vacant or unoccupied?"
            >
                <v-radio
                    label="Yes"
                    value=Yes
                ></v-radio>
                <v-radio
                    label="No"
                    value=No
                ></v-radio>
            </v-radio-group>
            <v-col>
                <v-card-text>
                    Extrerior Wall construction
                </v-card-text>
                <v-col>

                    <v-checkbox label="Frame"
                                v-model="form.exterior_wall"
                                value="Frame">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Joisted Masonry"
                                v-model="form.exterior_wall"
                                value="Joisted Masonry">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Non Combustible"
                                v-model="form.exterior_wall"
                                value="Non Combustible">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Masonry Non Combustible"
                                v-model="form.exterior_wall"
                                value="Masonry Non Combustible">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Fire Resistive"
                                v-model="form.exterior_wall"
                                value="Fire Resistive">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Others (Specify)"
                                v-model="form.exterior_wall"
                                value="Others (Specify)">

                    </v-checkbox>
                </v-col>

            </v-col>

            <v-radio-group
                v-model="form.basement"
                row
                label="3. Basement"
            >
                <v-radio
                    label="Yes"
                    value=Yes
                ></v-radio>
                <v-radio
                    label="No"
                    value=No
                ></v-radio>
            </v-radio-group>

            <v-text-field
            label="4. Esmitated building age"
            v-model="form.age">

            </v-text-field>
            <v-text-field
                label="5.Height"
                v-model="form.height">

            </v-text-field>

            <v-col>
                <v-card-text>
                    Roof:
                </v-card-text>
                <v-col>

                    <v-checkbox label="Flat"
                                v-model="form.roof"
                                value="Flat">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Bowstring"
                                v-model="form.roof"
                                value="Bowstring">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Pitched"
                                v-model="form.roof"
                                value="Pitched">

                    </v-checkbox>
                </v-col>


            </v-col>
            <v-text-field
                label="6a. Estimated Age"
                v-model="form.roof_age">

            </v-text-field>

            <v-col>
                <v-card-text>
                    7. Construction and general use
                </v-card-text>
                <v-col>

                    <v-checkbox label="Apartments"
                                v-model="form.construction_use"
                                value="Apartments">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Commercial"
                                v-model="form.construction_use"
                                value="Commercial">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Commercial & Dwelling"
                                v-model="form.construction_use"
                                value="Commercial & Dwelling">

                    </v-checkbox>
                </v-col>


            </v-col>
            <v-row>
                <v-col>
                    <v-checkbox label="8.Other buildings on lot:"
                                v-model="form.other_buildings"
                                value="8.Other buildings on lot:">

                    </v-checkbox>

                </v-col>
                <v-col>
                    <v-text-field
                        label="Type"
                        v-model="form.type">

                    </v-text-field>
                    <v-text-field
                        label="Construction"
                        v-model="form.construction">

                    </v-text-field>
                    <v-text-field
                        label="Occupied as:"
                        v-model="form.occupied_as">

                    </v-text-field>
                    <v-row>
                        <v-col>
                            <v-card-text>Condition:</v-card-text>
                        </v-col>
                        <v-col>

                            <v-checkbox label="Good"
                                        v-model="form.condition"
                                        value="Good">

                            </v-checkbox>
                        </v-col>    <v-col>
                        <v-checkbox label="Fair"
                                    v-model="form.condition"
                                    value="Fair">

                        </v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox label="Poor"
                                    v-model="form.condition"
                                    value="Poor">

                        </v-checkbox>

                    </v-col>
                    </v-row>




                </v-col>

            </v-row>
            <v-text-field
                label="9.If this is an apartment building, number of apartments"
                v-model="form.apartments_number">

            </v-text-field>
            <v-textarea
                label="Comments:"
                v-model="form.comments">

            </v-textarea>
        </v-col>

                <v-col>

                    <v-card-title>
                        B. BUILDING MECHANICS
                    </v-card-title>

                        1) Heating

                    <v-row>
                        <v-col>
                        <v-card-text>
                            (a.) Type:
                        </v-card-text>
                 </v-col>
                        <v-col>
                            <v-checkbox label="Hot Air"
                                        v-model="form.heating_type"
                                        value="Hot Air">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Hot Water"
                                        v-model="form.heating_type"
                                        value="Hot Water">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Steam"
                                        v-model="form.heating_type"
                                        value="Steam">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Unit or Space heater"
                                        v-model="form.heating_type"
                                        value="Unit or Space heater">

                            </v-checkbox>

                        </v-col>
                        <v-col>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-text>
                                (b.) Fuel:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Gas"
                                        v-model="form.heating_fuel"
                                        value="Gas">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Oil"
                                        v-model="form.heating_fuel"
                                        value="Oil">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Coal"
                                        v-model="form.heating_fuel"
                                        value="Coal">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Electric"
                                        v-model="form.heating_fuel"
                                        value="Electric">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="LP Gas"
                                        v-model="form.heating_fuel"
                                        value="LP Gas">

                            </v-checkbox>

                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (c.) Fuel Storage:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Inside"
                                        v-model="form.heating_fuel_storage"
                                        value="Inside">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Outside"
                                        v-model="form.heating_fuel_storage"
                                        value="Outside">

                            </v-checkbox>

                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (e.) Condition of heating system:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Good"
                                        v-model="form.heating_condition"
                                        value="Good">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Fair"
                                        v-model="form.heating_condition"
                                        value="Fair">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Poor"
                                        v-model="form.heating_condition"
                                        value="Poor">

                            </v-checkbox>

                        </v-col>

                        <v-col>

                        </v-col>

                        <v-col>

                        </v-col>

                    </v-row>
                    2. Wiring
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (a.) Electrical service:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="110"
                                        v-model="form.wiring_service"
                                        value="110">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="220"
                                        v-model="form.wiring_service"
                                        value="220">

                            </v-checkbox>

                        </v-col>

                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (b.) Protection:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="None"
                                        v-model="form.wiring_protection"
                                        value="None">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Circuit breakers"
                                        v-model="form.wiring_protection"
                                        value="Circuit breakers">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Fused"
                                        v-model="form.wiring_protection"
                                        value="Fused">

                            </v-checkbox>

                        </v-col>


                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col>
                            <v-checkbox label="Fustats"
                                        v-model="form.wiring_protection"
                                        value="Fustats">

                            </v-checkbox>

                        </v-col>

                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-text>
                                (c.) Is system over fused?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.wiring_system"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.wiring_system"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-text>
                                (d.) Does wiring apper adequate?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.wiring_adequete"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.wiring_adequete"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-text-field
                    label="(e.) If Apartment building, number of circuits per apartment:"
                    v-model="form.wiring_circuits">

                    </v-text-field>

                    3. Building Condition
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (a.) General Condition:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Excellent"
                                        v-model="form.building_general"
                                        value="Excellent">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Average"
                                        v-model="form.building_general"
                                        value="Average">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Fair"
                                        v-model="form.building_general"
                                        value="Fair">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Poor"
                                        v-model="form.building_general"
                                        value="Poor">

                            </v-checkbox>

                        </v-col>

                        <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (b.) Evidence of deterioration?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.building_evidence"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.building_evidence"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (c.) Condition of Common Areas (halls, stairs, basement:)
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Good"
                                        v-model="form.building_condition"
                                        value="Good">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Fair"
                                        v-model="form.building_condition"
                                        value="Fair">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Poor"
                                        v-model="form.building_condition"
                                        value="Poor">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-text>
                                (d.) General Building Maintenance:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Excellent"
                                        v-model="form.building_general_maintence"
                                        value="Excellent">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Adequate"
                                        v-model="form.building_general_maintence"
                                        value="Adequate">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Marginal"
                                        v-model="form.building_general_maintence"
                                        value="Marginal">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="Poor"
                                        v-model="form.building_general_maintence"
                                        value="Poor">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card-text>
                                (e.) Broken Windows?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.building_windows"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.building_windows"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (f.) Any unrepaired fire damage?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.building_fire_damage"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.building_fire_damage"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (g.) Adequate sanitary facilities?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.building_sanitary"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.building_sanitary"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (h.) Unsafe cooking devices?
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Yes"
                                        v-model="form.building_cooking"
                                        value="Yes">

                            </v-checkbox>

                        </v-col>
                        <v-col>
                            <v-checkbox label="No"
                                        v-model="form.building_cooking"
                                        value="No">

                            </v-checkbox>

                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>

                    <v-textarea
                    label="Comments"
                    v-model="form.building_comments">


                    </v-textarea>
                </v-col>

                <v-card-title>

                    <v-card-title>
                       C. HOUSEKEEPING
                    </v-card-title>
                </v-card-title>
                1. Building
                <v-col>

                   <v-row>
                       <v-col>
                       <v-card-text>
                           (a.) General Housekeeping
                       </v-card-text>
                   </v-col>
                       <v-col>
                           <v-checkbox label="Good"
                                       v-model="form.general_housekeeping"
                                       value="Good">

                           </v-checkbox>

                       </v-col>
                       <v-col>
                           <v-checkbox label="Fair"
                                       v-model="form.general_housekeeping"
                                       value="Fair">

                           </v-checkbox>

                       </v-col>
                       <v-col>
                           <v-checkbox label="Poor"
                                       v-model="form.general_housekeeping"
                                       value="Poor">

                           </v-checkbox>

                       </v-col>

                   </v-row>
                    <v-row>
                        <v-col>
                            <v-card-text>
                                (b.) Trash Collection:
                            </v-card-text>
                        </v-col>
                        <v-col>
                            <v-col>
                                <v-card-text>
                                    (1.) Trash Containers
                                </v-card-text>


                                <v-radio-group
                                    v-model="form.trash_containers"
                                    row

                                >
                                    <v-radio
                                        label="Yes"
                                        value="Yes"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        value=No
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <v-card-text>
                                    (2.) Evidence of regular trash collection
                                </v-card-text>


                                <v-radio-group
                                    v-model="form.trash_regular"
                                    row

                                >
                                    <v-radio
                                        label="Yes"
                                        value="Yes"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        value=No
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <v-card-text>
                                    (3.) Evidence of rodent infestation
                                </v-card-text>


                                <v-radio-group
                                    v-model="form.trash_rodent"
                                    row

                                >
                                    <v-radio
                                        label="Yes"
                                        value="Yes"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        value=No
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col>
                                <v-card-text>
                                    (4.) Evidence of insect infestation
                                </v-card-text>


                                <v-radio-group
                                    v-model="form.trash_insect"
                                    row

                                >
                                    <v-radio
                                        label="Yes"
                                        value="Yes"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        value=No
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>


                        </v-col>

                    </v-row>

                </v-col>

                <v-col>
                <v-card-title>
                    BUILDING - GENERAL
                </v-card-title>
                    1. Building occupancy (Describe occupancies and if Contents Policy indicate where our applicant's property is located)

                    <v-col>
                        <v-text-field
                        label="(a.) Basement"
                        v-model="form.basement">

                        </v-text-field>
                        <v-text-field
                        label="(b.) Ground floor or 1st floor"
                        v-model="form.ground_floor">

                        </v-text-field>
                        <v-text-field
                        label="(c.) 2nd"
                        v-model="form.second_floor">

                        </v-text-field>
                        <v-text-field
                        label="(d.) 3rd"
                        v-model="form.third_floor">

                        </v-text-field>
                        <v-text-field
                        label="(e.) Other"
                        v-model="form.other_buildings">

                        </v-text-field>
                    </v-col>

                    2. Building Exposures

                    <v-row>
                        <v-col>
                            <v-text-field
                            label="East"
                            v-model="form.east">

                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Vacant"
                                        v-model="form.east_vacant"
                                        value="Vacant">

                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                            label="West"
                            v-model="form.west">

                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Vacant"
                                        v-model="form.west_vacant"
                                        value="Vacant">

                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                            label="North"
                            v-model="form.north">

                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Vacant"
                                        v-model="form.north_vacant"
                                        value="Vacant">

                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                            label="South"
                            v-model="form.south">

                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Vacant"
                                        v-model="form.south_vacant"
                                        value="Vacant">

                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-text-field
                        v-model="form.fire_department_name"
                    label="3. Name of Fire Department responding">

                    </v-text-field>

                    <v-radio-group
                        v-model="form.private_protection"
                        row
                        label="4. Private Protection (sprinklers, portable extinguishers, smoke alarms, etc.):"

                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value=No
                        ></v-radio>
                    </v-radio-group>

                    <v-row>
                        <v-col>
                        <v-card-text>
                            5. General Comments on Applicant's business:
                        </v-card-text></v-col>
                        <v-col>
                        <v-checkbox label="N/A"
                                    v-model="form.general_comments"
                                    value="N/A">

                        </v-checkbox>
                            </v-col>
                    </v-row>
                    <v-text-field
                        label="Request is for Bldg."
                    v-model="form.general_comments_na">

                    </v-text-field>
                    <v-text-field
                        label="6. Applicant's interest in this property"
                    v-model="form.applicant_interest">

                    </v-text-field>


                </v-col>
                <v-col>
                    <v-card-title>
                        F. OVERALL BUILDING EVALUATION
                    </v-card-title>


                    <v-radio-group
                        v-model="form.overall"


                    >
                        <v-row>

                        <v-col md="2">
                            <v-radio
                                label="Excellent"
                                value="Excellent"
                            ></v-radio>
                        </v-col>
                       <v-col md="10">
                           <v-card-text>
                           Building in like-new condition; no evidence of physical deterioration;
                           occupied by the use for which originally intended;
                           all building services are modern,proper and adequate
                       </v-card-text>

                       </v-col>


                        </v-row>
                        <v-row>

                        <v-col md="2">
                            <v-radio
                                label="Good"
                                value="Good"
                            ></v-radio>
                        </v-col>
                       <v-col md="10">
                           <v-card-text>
                               Minor deterioration visible; building still used as it
                               was originally intendedl; building services proper and adequate
                       </v-card-text>

                       </v-col>


                        </v-row>
                        <v-row>

                        <v-col md="2">
                            <v-radio
                                label="Average"
                                value="Average"
                            ></v-radio>
                        </v-col>
                       <v-col md="10">
                           <v-card-text>
                               Normal wear and tear apparent; building still used as it was
                               originally intended, or occupied by a use for which it was
                               renovated; building services proper and adequate
                       </v-card-text>

                       </v-col>


                        </v-row>
                        <v-row>

                        <v-col md="2">
                            <v-radio
                                label="Poor"
                                value="Poor"
                            ></v-radio>
                        </v-col>
                       <v-col md="10">
                           <v-card-text>
                              Definite deterioration obvious; building may be occupied by a use
                               other than originally intended; building services may be partially removed
                               or unused, or made adequate through adaption for present occupancy
                       </v-card-text>

                       </v-col>


                        </v-row>
                        <v-row>

                        <v-col md="2">
                            <v-radio
                                label="Very Poor"
                                value="Very Poor"
                            ></v-radio>
                        </v-col>
                       <v-col md="10">
                           <v-card-text>
                              Approaching unsound condition; building or portions
                               thereof might be unusablel; some building services
                               might be unused
                       </v-card-text>

                       </v-col>


                        </v-row>

                    </v-radio-group>

                </v-col>
                <v-col>
                    <h1>Building diagram</h1>
                    <canvas ref="canvas" id='drawing-pad' width='600' height='330'>

                    </canvas>
                    <div>

                        <div @click='resetCanvas'>        Reset Canvas    </div>


                    </div>
                    <hr>
                </v-col>



                    <v-btn
                    @click="submit">
                        Add

                    </v-btn>
            </v-form>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import axios from 'axios';
import image from "../../../assets/canvas_test.png"
export default {
    name: "Commercial",
    components: {UserDashboardLayout},
    data: function () {
        return {
            img:null,
            canvas: null,
            context: null,
            isDrawing: false,
            startX: 0,
            startY: 0,
            points: [],
            image:image,

            form:{
                applicant_name:'',
                property_address:'',
                fire_divison_address:'',
                city:'',
                within_city_limits:'',
                inspection_date:'',
                interior_inspection:'',
                person_contacted_name:'',
                date_called:'',
                file:'',
                inspection:'',
                insp_co:'',
                inspector:'',
                property_vacant:'',
                exterior_wall:'',
                basement:'',
                age:'',
                height:'',
                roof:'',
                roof_age:'',
                construction_use:'',
                other_buildings:'',
                type:'',
                construction:'',
                occupied_as:'',
                condition:'',
                apartments_number:'',
                comments:'',
                heating_type:'',
                heating_fuel:'',
                heating_fuel_storage:'',
                heating_hazard:'',
                heating_condition:'',
                wiring_service:'',
                wiring_protection:'',
                wiring_system:'',
                wiring_adequete:'',
                wiring_circuits:'',
                building_general:'',
                building_evidence:'',
                building_condition:'',
                building_general_maintence:'',
                building_windows:'',
                building_fire_damage:'',
                building_sanitary:'',
                building_cooking:'',
                building_comments:'',
                general_housekeeping:'',
                trash_containers:'',
                trash_regular:'',
                trash_evidence:'',
                trash_rodent:'',
                trash_insect:'',
                common_condition:'',
                applicant_area:'',

                basement_condition:'',
                flammable_stock:'',
                house_comments:'',
                building_basement:'',
                ground_floor:'',
                second_floor:'',
                third_floor:'',
                building_other:'',
                east:'',
                east_vacant:'',
                west:'',
                west_vacant:'',
                north:'',
                north_vacant:'',
                south:'',
                south_vacant:'',
                fire_department_name:'',
                private_protection:'',
                last_inspection:'',
                general_comments:'',
                general_comments_na:'',
                applicant_interest:'',
                overall:'',

            }
        }
    },
    mounted(){
        var vm = this
        vm.canvas = vm.$refs.canvas
        vm.context = vm.canvas.getContext("2d");
        vm.canvas.addEventListener('mousedown', vm.mousedown);
        vm.canvas.addEventListener('mousemove', vm.mousemove)
        document.addEventListener('mouseup', vm.mouseup);

        var   base_image = new Image();

        base_image.src = this.image;
        console.log(base_image)
        base_image.onload = function(){
            console.log(1)
            vm.context.drawImage(base_image, 0, 0);
        }
    },

    methods: {
        submit() {
            var vm = this
            var dataURL = vm.canvas.toDataURL();
           // var img = vm.$refs.img;
            let form = new FormData();
            form.append('img',dataURL)
            form.append('app',this.form.applicant_name)

            axios({
                url:  '/api/commercial-inspection/canvas',
                method: 'POST',
                cache: false,
                contentType: false,
                processData: false,
                data : form,

                headers: {
                    'Content-Type': 'multipart/form-data'
            }

            }).then((response)=>{
                console.log(response)
                axios({
                    url:  '/api/commercial-inspection',
                    method: 'POST',
                    data:this.form,
                    responseType: 'blob',
//
                })
                    .then((response) => {
                        console.log(this.form.inspector)
                        if(response.status == 200){
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'test.pdf');
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    }).finally(() => {
                    //Perform action in always
                });

            })

        },

        mousedown(e){
            var vm = this
            var rect = vm.canvas.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;

            vm.isDrawing = true;
            vm.startX = x;
            vm.startY = y;
            vm.points.push({
                x: x,
                y: y
            });
        },
        mousemove(e){
            var vm = this
            var rect = vm.canvas.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;

            if (vm.isDrawing) {
                vm.context.beginPath();
                vm.context.moveTo(vm.startX, vm.startY);
                vm.context.lineTo(x, y);
                vm.context.lineWidth = 1;
                vm.context.lineCap = 'round';
                vm.context.strokeStyle = "rgba(0,0,0,1)";
                vm.context.stroke();

                vm.startX = x;
                vm.startY = y;

                vm.points.push({
                    x: x,
                    y: y
                });
            }
        },
        mouseup(){
            var vm = this
            vm.isDrawing = false;
            if (vm.points.length > 0) {
                localStorage['points'] = JSON.stringify(vm.points);
            }
        },
        resetCanvas(){
            var vm = this
         //   vm.canvas.width = vm.canvas.width;
          //  vm.points.length = 0; // reset points array
            const context = vm.canvas.getContext('2d');

            context.clearRect(0, 0, vm.canvas.width, vm.canvas.height);
            var   base_image = new Image();

            base_image.src = this.image;

            base_image.onload = function(){
                console.log(1)
                vm.context.drawImage(base_image, 0, 0);
            }
        },
        saveImage(){
            var vm = this
            var dataURL = vm.canvas.toDataURL();
            var img = vm.$refs.img;
            img.src = dataURL;
        },
        replay(){
            var vm = this
         //   vm.canvas.width = vm.canvas.width;

            // load localStorage
            if (vm.points.length === 0) {
                if (localStorage["points"] !== undefined) {
                    vm.points = JSON.parse(localStorage["points"]);
                }
            }

            var point = 1;
            setInterval(function(){
                drawNextPoint(point);
                point += 1;
            },10);

            function drawNextPoint(index) {
                if (index >= vm.points.length) {
                    return;
                }
                var startX = vm.points[index-1].x;
                var startY = vm.points[index-1].y;

                var x = vm.points[index].x;
                var y = vm.points[index].y;

                vm.context.beginPath();
                vm.context.moveTo(startX, startY);
                vm.context.lineTo(x, y);
                vm.context.lineWidth = 1;
                vm.context.lineCap = 'round';
                vm.context.strokeStyle = "rgba(0,0,0,1)";
                vm.context.stroke();
            }
        },
    }

}
</script>

<style scoped>

</style>
